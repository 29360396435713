import React, { useState } from 'react';
import { whitelist } from './whitelistData';
import { toast, Toaster } from 'sonner';
import Logo from './logo.png';
import { FaTwitter } from 'react-icons/fa';
import Coll from './dpunks.png';
import './App.css';

const WhitelistChecker = () => {
    const [address, setAddress] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [darkMode, setDarkMode] = useState(false);

    // Function to validate if the address is a valid Ethereum address or ENS name
    const isValidAddressOrENS = (input) => {
        const ethAddressRegex = /^0x[a-fA-F0-9]{40}$/;
        const ensRegex = /^[a-zA-Z0-9-]+\.eth$/;
        return ethAddressRegex.test(input) || ensRegex.test(input);
    };

    const checkEligibility = () => {
        if (!isValidAddressOrENS(address.trim())) {
            setIsValid(false);
            toast.error('Invalid address format. Please enter a valid address (0x...) or ENS name (example.eth).');
            return;
        }
        setIsValid(true);
        if (whitelist.includes(address.trim())) {
            toast.success('Your address is whitelisted!');
        } else {
            toast.error('Your address is not whitelisted.');
        }
    };

    const handlePaste = async () => {
        const text = await navigator.clipboard.readText();
        setAddress(text.trim());
    };

    return (
        <div className={`App ${darkMode ? 'dark' : ''}`}>
            <div className='header'>
                <img 
                    className='logo'
                    src={Logo} 
                    alt='dpunks'
                />
            </div>
            <div className="whitelist-box">
                <h2>Whitelist Checker</h2>
                <div className="input-group">
                    <input
                        type="text"
                        placeholder="Enter your Base address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className={!isValid ? 'invalid' : ''}
                    />
                    <button className="paste-button" onClick={handlePaste}>Paste</button>
                </div>
                <button className="check-button" onClick={checkEligibility}>Check Eligibility</button>
                <Toaster position="top-left" richColors />
            </div>

            <img 
                src={Coll} 
                alt="Coll" 
                className="fixed-image" 
            />

            <footer className="footer">
                <a 
                    href="https://x.com/dpunks_" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="twitter-link"
                >
                    <FaTwitter className="twitter-icon" />
                </a>
                <p>© 2024 dpunks. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default WhitelistChecker;
